import { inject, Injectable } from '@angular/core';
import { catchError, from, Observable, of } from 'rxjs';
import { Models } from 'appwrite';

import { AppwriteClientService } from '../appwrite/appwrite-client.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private clientService = inject(AppwriteClientService);

  getProfile(): Observable<null | Models.User<Models.Preferences>> {
    return from(this.clientService.account.get()).pipe(
      catchError(() => of(null)),
    );
  }

  login(email: string, password: string) {
    return from(
      this.clientService.account.createEmailPasswordSession(email, password),
    );
  }

  logout() {
    return from(this.clientService.account.deleteSessions());
  }

  recoverPassword(email: string) {
    return from(
      this.clientService.account.createRecovery(
        email,
        'http://localhost:4200/auth/recover',
      ),
    );
  }

  updateRecovery(userId: string, secret: string, pwd: string) {
    return from(this.clientService.account.updateRecovery(userId, secret, pwd));
  }

  updateRegistrationToken(token: string) {
    return from(
      this.clientService.account.updatePrefs({
        registrationToken: token,
      }),
    );
  }
}
