import { inject, Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { UserService } from '@app/core/users/user.service';
import { filter, lastValueFrom, map, mergeMap } from 'rxjs';
import { Query } from 'appwrite';
import { injectQueryClient } from '@tanstack/angular-query-experimental';

@Injectable({ providedIn: 'root' })
export class NotificationLoader {
  private notificationsService = inject(NotificationsService);
  private userService = inject(UserService);
  private queryClient = injectQueryClient();

  constructor() {
    this.notificationsService.subscribeChanges().subscribe(() => {
      this.reloadNotifications();
    });
  }

  unreadCountQuery() {
    return () => ({
      queryKey: ['unreadCount'],
      queryFn: () => lastValueFrom(this.unreadCount()),
    });
  }

  notificationQuery() {
    return () => ({
      queryKey: ['notifications'],
      queryFn: () => lastValueFrom(this.loadNotifications()),
    });
  }

  reloadNotifications() {
    this.queryClient.refetchQueries({
      queryKey: ['unreadCount'],
    });
    this.queryClient.refetchQueries({
      queryKey: ['notifications'],
    });
  }

  private unreadCount() {
    return this.userService.getProfile().pipe(
      filter(Boolean),
      mergeMap((user) =>
        this.notificationsService.listNotifications([
          Query.equal('target', user.$id),
          Query.equal('read', false),
        ]),
      ),
      map(({ total }) => total),
    );
  }

  private loadNotifications() {
    return this.userService.getProfile().pipe(
      filter(Boolean),
      mergeMap((user) => {
        return this.notificationsService.listNotifications([
          Query.equal('target', user.$id),
        ]);
      }),
      map(({ documents }) => documents),
    );
  }
}
