import { Injectable } from '@angular/core';
import { Account, Client, Databases, RealtimeResponseEvent } from 'appwrite';
import { environment } from '@environments/environment';
import { filter, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppwriteClientService<T = unknown> {
  readonly account: Account;
  readonly client: Client;
  readonly databases: Databases;
  private events$: Observable<RealtimeResponseEvent<T>>;

  constructor() {
    this.client = new Client()
      .setEndpoint(environment.baseURL) // Your API Endpoint
      .setProject(environment.projectId); // Your project ID

    this.account = new Account(this.client);
    this.databases = new Databases(this.client);

    this.events$ = new Observable((obs) => {
      const baseChannel = `documents`;
      this.client.subscribe<T>(baseChannel, (result) => {
        obs.next(result);
      });
    });
  }

  subscribeChannel(channel: string) {
    return this.events$.pipe(filter((event) => event.events.includes(channel)));
  }
}
