export const environment = {
  baseURL: 'https://appwrite.touch4it.com/v1',
  projectId: '66d5b465003839589f70',
  db: 'dev',
  googleMapsKey: 'AIzaSyD_aDTXq8cuH2xC0y2b_ubH_gJHLN378uU',
  env: 'DEV',
  vapidKey:
    'BIMsZHxXpodTQM2FdY50mlvng1a1V10VI858_CCw1jX3QXSTtoMLUMy4XhJ7axBv4kLojOboASnvatYMkpE2Ilw',
  firebase: {
    projectId: 'ntfier',
    appId: '1:137716955490:web:ef896752a02bea0d6f943b',
    storageBucket: 'ntfier.appspot.com',
    apiKey: 'AIzaSyCocX_0Mwd7ancBEaJtrgtlglzdMe4MxqQ',
    authDomain: 'ntfier.firebaseapp.com',
    messagingSenderId: '137716955490',
  },
};
