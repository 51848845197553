import { inject, Injectable } from '@angular/core';
import { CrudService } from '@app/core/services/crud.service';
import { AppwriteClientService } from '@app/core/appwrite/appwrite-client.service';
import { Query } from 'appwrite';
import { NotificationDocument } from '@app/core/notifications/notification';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private clientService: AppwriteClientService<NotificationDocument> = inject(
    AppwriteClientService,
  );
  private crud!: CrudService<NotificationDocument>;

  constructor() {
    this.crud = new CrudService<NotificationDocument>(
      this.clientService,
      'notifications',
    );
  }

  listNotifications(queries?: string[]) {
    return this.crud.listDocuments([
      Query.orderDesc('$createdAt'),
      Query.limit(100),
      ...(queries ?? []),
    ]);
  }

  readNotification(id: string) {
    return this.crud.updateDocument(id, {
      read: true,
    });
  }

  subscribeChanges() {
    return this.clientService.subscribeChannel(
      `databases.${environment.db}.collections.notifications`,
    );
  }
}
