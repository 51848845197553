import { from } from 'rxjs';
import { environment } from '@environments/environment';
import { ID, Models } from 'appwrite';

import { AppwriteClientService } from '../appwrite/appwrite-client.service';

export class CrudService<T extends Models.Document> {
  constructor(
    private appwriteClientService: AppwriteClientService,
    private collectionId: string,
  ) {}

  listDocuments(queries?: string[]) {
    return from(
      this.appwriteClientService.databases.listDocuments<T>(
        environment.db,
        this.collectionId,
        queries,
      ),
    );
  }

  getDocument(id: string) {
    return from(
      this.appwriteClientService.databases.getDocument<T>(
        environment.db,
        this.collectionId,
        id,
      ),
    );
  }

  updateDocument(id: string, body: Partial<T>) {
    return from(
      this.appwriteClientService.databases.updateDocument<T>(
        environment.db,
        this.collectionId,
        id,
        body,
      ),
    );
  }

  createDocument(body: Omit<T, keyof Models.Document>) {
    return from(
      this.appwriteClientService.databases.createDocument<T>(
        environment.db,
        this.collectionId,
        ID.unique(),
        body,
      ),
    );
  }

  deleteDocument(id: string) {
    return from(
      this.appwriteClientService.databases.deleteDocument(
        environment.db,
        this.collectionId,
        id,
      ),
    );
  }
}
